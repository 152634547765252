@font-face {
  font-family: 'ITC-Avant-B';
  src: url(../public/assets/fonts/ITC\ Avant\ Garde\ Gothic\ Std\ Bold.otf);
}

@font-face {
  font-family: 'ITC-Avant-BK';
  src: url(../public/assets/fonts/ITC\ Avant\ Garde\ Gothic\ Std\ Book.otf);
}

@font-face {
  font-family: 'ITC-Avant-D';
  src: url(../public/assets/fonts/ITC\ Avant\ Garde\ Gothic\ Std\ Demi.otf);
}

@font-face {
  font-family: 'ITC-Avant-EL';
  src: url(../public/assets/fonts/ITC\ Avant\ Garde\ Gothic\ Std\ Extra\ Light.otf);
}

@font-face {
  font-family: 'ITC-Avant-M';
  src: url(../public/assets/fonts/ITC\ Avant\ Garde\ Gothic\ Std\ Medium.otf);
}


#root {
  height: 100%;
}


html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  /* font-family: 'Inter', sans-serif; */
  font-family: 'ITC-Avant-EL';
  background: #1B1829;
  overflow-x: hidden;
  color: #fff;

}

.listed-link {
  display: inline-block;
  transition: all 0.25s linear;
}

.listed-link:hover {
  transform: translateY(-10px);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}


.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.light-text {
  color: #6D6C69 !important;
}

.dark-text {
  color: #2B1D11 !important;
}

.primary-text {
  color: #E63E89 !important;
}

.fs-14 {
  font-size: 14px !important;
}

small {
  color: #70EC9D;
}

.lh {
  line-height: normal;
}

p {
  color: rgba(255, 255, 255, .7);
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
}

h2 {
  color: #fff;
  font-size: 52px;
  text-transform: uppercase;
  letter-spacing: 5%;

}
h3 {
  color: #fff;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 5%;
  font-weight: 500;
  font-family: 'Inter', sans-serif !important;
}

h2 b {
  font-family: 'ITC-Avant-B';
}

.br-30 {
  border-radius: 30px;
}

.br-10 {
  border-radius: 10px;
}

.hy-40 {
  max-height: 40px;
}

.card-clr {
  background: linear-gradient(183.73deg, rgba(230, 62, 137, 0.4) 6.12%, rgba(160, 87, 207, 0.4) 93.88%);
  padding: 1px;
  border-radius: 32px;
}

.card-clr .h-100 {
  background: #1B1829;
  border-radius: 32px;
}

.card-detail {
  background: linear-gradient(183.73deg, rgba(230, 62, 137, 0.08) 6.12%, rgba(160, 87, 207, 0.08) 93.88%);
  border-radius: 32px;
  padding: 25px;
  padding-top: 35px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.inter {
  font-family: 'Inter', sans-serif !important;
}

/* Header */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0px;
}

.nav-bg {
  background: linear-gradient(90deg, rgb(255 255 255 / 0%), rgba(255, 255, 255, .3));
  padding: 10px 15px;
  border-bottom: 2px solid #DFDAD6;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  max-width: 1300px !important;
}

.social-ic {
  display: inline-block;
  transition: all .2s ease-in-out;
}

.social-ic:hover {
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;
}

.bg-pink {
  /* background: #fff7f272; */
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, .7);
  margin: 0 4px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #E63E89 !important;
  transition: all .2s ease-in-out;
}


.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}


/* Banner */
.banner {
  /* background: url(../public/assets/bannerBG.png); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding-top: 100px;
}
.bg-2 {
  background: url(../public/assets/bg-2.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.banner h1 {
  font-size: 80px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'ITC-Avant-El';
  letter-spacing: 5%;
}

/* .banner p {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
} */
.bg-blend {
  background: #1B1829;
}

.bg-blend img {
  mix-blend-mode: screen;
}

.primary-btn {
  color: #fff;
  border-radius: 30px;
  background: linear-gradient(109.72deg, #00E7AE 0%, #A057CF 100%);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 25px;
  height: 50px;
  font-family: 'ITC-Avant-BK';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, 0px);
  transition: all .2s ease-in-out;
}


.primary-btn:hover {
  background: linear-gradient(209.72deg, #00E7AE 0%, #A057CF 100%);
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;

}

.primary-btn-outline {
  color: #fff;
  border-radius: 30px;
  background: linear-gradient(273.73deg, #E63E89 6.12%, #A057CF 87.68%);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 25px;
  height: 50px;
  font-family: 'ITC-Avant-BK';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, 0px);
  transition: all .2s ease-in-out;
}

.primary-btn-outline:hover {
  background: linear-gradient(173.73deg, #E63E89 6.12%, #A057CF 87.68%);
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;
}


.z-1 {
  z-index: 1;
}




@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }


  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: linear-gradient(180deg, rgba(34, 20, 59, 1), rgba(34, 20, 59, 1));
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
    color: #FD6746 !important;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }



  /* .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
  } */

  .navbar-nav::after {
    content: none;
  }



}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}


h4 {
  font-size: 32px;
  font-family: 'ITC-Avant-B';
  text-transform: uppercase;
  letter-spacing: 5%;
  background: linear-gradient(109.51deg, #00E7AE 0%, #A057CF 58.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

h4 span {
  color: white;
  background: none;
  -webkit-text-fill-color: white;
  font-family: 'ITC-Avant-BK';
}

.card-detail p {
  font-size: 16px;
  font-family: 'ITC-Avant-EL';
  line-height: 28px;
}

.pool h4 {
  -webkit-text-fill-color: white;
}


.tab-content {
  min-height: 120px;
}

.nav-pills .nav-link {
  text-align: start;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all .2s ease-in-out;
  border-left: 3px solid rgba(126, 176, 251, 0);
  padding: 0;
}

.nav-pills .nav-link.active {
  padding: 0;
  background: #1B1829;
  transition: all .2s ease-in-out;
}

.nav-pills .nav-link.active h4 {
  font-size: 32px;
  font-family: 'ITC-Avant-B' !important;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 5%;
  background: linear-gradient(109.51deg, #00E7AE 0%, #A057CF 58.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;

}

.nav-pills .nav-link .bg-13 {
  padding: 25px;
}

.nav-pills .nav-link.active .bg-11 {
  background: linear-gradient(183.73deg, rgba(230, 62, 137, 0.4) 6.12%, rgba(160, 87, 207, 0.4) 93.88%);
  padding: 1px;
  border-radius: 32px;
}

.nav-pills .nav-link.active .bg-12 {
  background: #1B1829;
  border-radius: 32px;
}

.nav-pills .nav-link.active .bg-13 {
  background: linear-gradient(183.73deg, rgba(230, 62, 137, 0.08) 6.12%, rgba(160, 87, 207, 0.08) 93.88%);
  border-radius: 32px;
  height: 100%;
}

.nav-pills .nav-link .bg-13 p {
  font-size: 16px;
  line-height: 24px;
}


.d-able {
  background: #2B1D11 !important;
  color: #6D6C69 !important;
  pointer-events: none;
}

.d-able-link {
  color: #6D6C69 !important;
  pointer-events: none;
}

footer {
  padding-top: 50px;
}

.footer-link {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, .7);
  line-height: 20px;
  display: block;
  transition: all .2s ease-in-out;
}

.bt-1 {
  background: linear-gradient(183.73deg, rgba(230, 62, 137, 0.24) 6.12%, rgba(160, 87, 207, 0.24) 93.88%);
  height: 1px;

}

.footer-link:hover {
  color: #E63E89 !important;
  transition: all .2s ease-in-out;
}

.footer-ic {
  display: inline-block;
  transition: all .2s ease-in-out;
}

.footer-ic:hover {
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;
}

.kak-logo {
  height: 60px;
}
.light-logo{
  height: 50px;
}

@media(max-width: 767px) {
  
  .kak-logo {
    height: 34px;
  }

  .navbar .nav-link {
    color: #fff;
  }

  .banner {
    background-size: 170%;
    padding-top: 90px;
    background-position: top right;
  }

  .banner h1 {
    font-size: 42px;
    margin-top: 0px;
    margin-bottom: 0;
  }


  .banner p {
    font-size: 18px;
    line-height: 28px;
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
  }


  .nav-pills .nav-link {
    padding: 0px;
  }
  .nav-pills .nav-link .bg-13 {
    padding: 20px;
}
.nav-pills .nav-link.active h4 {
  font-size: 28px;
}
  h4 {
    font-size: 22px;
    line-height: 34px !important;
    margin-bottom: 10px;
  }

  .faq .accordion-body {
    padding-left: 0;
    padding-top: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }


  .fs-22 {
    font-size: 18px !important;
    line-height: 28px;
    padding: 0 20px;
  }

  .navbar-brand img {
    height: 40px;
  }

  footer {
    padding: 25px 0px 0 0;
  }

  .footer-link {
    margin-bottom: 4px;
    font-weight: 300;
  }

}